import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../../Shared/customStyle';
import { editHeroSection } from '../../../Redux/HomePage/HomePageActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader/Loader';
const HeroSectionModal = ({ isOpenModal, closeModal, data }) => {
  const [updatedData, setUpdatedData] = useState(data);
  const dispatch = useDispatch();
  const { error, loading } = useSelector((store) => store.homePageReducer);

  const handleInputChange = (e, index, field) => {
    const newData = updatedData.map((item, i) =>
      i === index ? { ...item, [field]: e.target.value } : item
    );
    setUpdatedData(newData);
  };

  const onChangeData = (event) => {
    event.preventDefault();
    if (!loading) {
      dispatch(editHeroSection(updatedData));
    }
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  useEffect(() => {
    if (!loading && !error) {
      closeModal();
    }
  }, [error, loading]);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {!loading && (
        <div className="w-full h-full text-white p-3">
          <h1 className="text-4xl w-full text-center">Edit Hero Section</h1>
          <form className="my-2" onSubmit={onChangeData}>
            <div className="mb-4">
              {updatedData.map((item, index) => (
                <div key={index} className="flex flex-col gap-1 mt-10">
                  <div className="flex flex-col gap-1">
                    <label>{item.subsection}</label>

                    <textarea
                      type="text"
                      value={item.content}
                      onChange={(e) => handleInputChange(e, index, 'content')}
                      className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-3 p-2 px-5 bg-red-600 text-white rounded-lg hover:bg-red-500"
                onClick={() => closeModal('cta')}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="p-2 px-5 bg-green-600 text-white rounded-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}

      {loading && (
        <div className=" w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
    </Modal>
  );
};

export default HeroSectionModal;

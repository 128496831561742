import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { editPackageData } from '../../../Redux/Packages/PackagesActions';
import { customStyles } from '../../../Shared/customStyle';
import Loader from '../../../Components/Loader/Loader';

const EditPackageModal = ({ isOpenModal, closeModal, data }) => {
  const [updatedData, setUpdatedData] = useState({
    package_name: '',
    price: 0,
    description: '',
    scents_number: 0,
    package_type: '',
  });

  const dispatch = useDispatch();

  const { loading, error } = useSelector((store) => store.packagesReducer);

  useEffect(() => {
    if (!loading && !error) {
      closeModal();
    }
  }, [error, loading]);

  useEffect(() => {
    if (data) {
      setUpdatedData({
        package_name: data.package_name,
        price: data.price,
        description: data.description,
        scents_number: data.scents_number,
        package_type: data.package_type,
      });
    }
  }, [data]);

  const handleInputChange = (field, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const onSubmit = () => {
    console.log({ UPDATEDDATA: updatedData });

    dispatch(
      editPackageData({
        ...updatedData,
        id: data.id,
      })
    );
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {!loading && (
        <div className="w-full h-full p-5">
          <h1 className="text-center text-4xl mb-5">Edit Perfume Package</h1>

          <form className="space-y-4">
            <div>
              <label className="block text-xl">Package Name</label>
              <input
                type="text"
                value={updatedData.package_name}
                onChange={(e) =>
                  handleInputChange('package_name', e.target.value)
                }
                className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
              />
            </div>

            <div>
              <label className="block text-xl">Package Type</label>
              <select
                value={updatedData.package_type}
                onChange={(e) =>
                  handleInputChange('package_type', e.target.value)
                }
                className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
              >
                <option value="Standard">Standard</option>
                <option value="Midrange">Midrange</option>
                <option value="Highend">Highend</option>
              </select>
            </div>

            <div>
              <label className="block text-xl">Price ($)</label>
              <input
                type="number"
                value={updatedData.price}
                onChange={(e) => handleInputChange('price', e.target.value)}
                className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
              />
            </div>

            <div>
              <label className="block text-xl">Scents Number</label>
              <input
                type="number"
                value={updatedData.scents_number}
                onChange={(e) =>
                  handleInputChange('scents_number', e.target.value)
                }
                className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
              />
            </div>

            <div>
              <label className="block text-xl">Description</label>
              <textarea
                value={updatedData.description}
                onChange={(e) =>
                  handleInputChange('description', e.target.value)
                }
                className="w-full p-2 mt-2 rounded bg-gray-800 border border-gray-600 text-white"
                rows="4"
              />
            </div>
          </form>

          <div className="flex justify-end mt-5 space-x-3">
            <button
              onClick={closeModal}
              className="px-5 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500"
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className="px-5 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500"
            >
              Save
            </button>
          </div>
        </div>
      )}

      {loading && <Loader />}
    </Modal>
  );
};

export default EditPackageModal;

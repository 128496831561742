// SalesChart.js
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthlySales } from '../Redux/Main/MainPageAction';

// Register necessary components for Chart.js
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const SalesChart = () => {
  const dispatch = useDispatch();
  const { monthlySales } = useSelector((store) => store.mainPageReducer);

  useEffect(() => {
    dispatch(getMonthlySales());
  }, [dispatch]);
  const data = {
    labels: monthlySales.map((item) => item.month),
    datasets: [
      {
        label: 'Sales',
        data: monthlySales.map((item) => parseFloat(item.total_sales)),
        fill: false,
        borderColor: 'white',
        tension: 0.1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Over Time',
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Set to false to hide the grid
          color: 'white', // Customize the grid line color
        },
      },
      y: {
        grid: {
          display: true, // Set to false to hide the grid
          color: 'white', // Customize the grid line color
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default SalesChart;

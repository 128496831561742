import axios from 'axios';
import { SubscriptionsAction } from './SubscriptionsReducer';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(SubscriptionsAction.GetSubscriptionsRequest());
    const _response = await axios.get(`${API_BASE_URL}/subscriptions`);
    dispatch(SubscriptionsAction.GetSubscriptionsSuccess(_response.data.data));
  } catch (error) {
    dispatch(SubscriptionsAction.GetSubscriptionsFailure(error.message));
  }
};

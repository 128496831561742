import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../../Shared/customStyle';
import { useDispatch, useSelector } from 'react-redux';
import { dISpecialBackgroundSection } from '../../../Redux/WomensPage/WomensPageActions';
import Loader from '../../../Components/Loader/Loader';
const SpecialBackgroundsModals = ({ isOpenModal, closeModal, data }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((store) => store.womenPageReducer);

  useEffect(() => {
    if (!loading && !error) {
      closeModal('deleteImage');
    }
  }, [loading, error]);
  const onDeleteImage = () => {
    dispatch(dISpecialBackgroundSection(data.id));
  };
  return (
    <div>
      <Modal
        isOpen={isOpenModal.deleteImage}
        onRequestClose={() => closeModal('deleteImage')}
        style={customStyles}
      >
        {!loading && (
          <div className="flex flex-col items-center gap-7 text-white bg-[#313131] p-3">
            <h1 className="text-4xl text-center mb-4">Delete the Image</h1>
            <img
              src={process.env.REACT_APP_IMAGE_BASE_URL + data.content}
              alt="Delete Preview"
              width={200}
              className="rounded shadow-lg"
            />
            <button
              className="bg-red-500 text-white px-5 p-2 border rounded-lg w-1/2 hover:bg-red-600 transition-colors"
              onClick={onDeleteImage}
            >
              Yes, Delete
            </button>
          </div>
        )}

        {loading && <Loader />}
      </Modal>
    </div>
  );
};

export default SpecialBackgroundsModals;

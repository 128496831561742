import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  products: [],
  isProductAdded: [],
  isProductUpdated: [],
  isProductDeleted: [],
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsRequest: (state) => {
      state.loading = true;
      state.error = false;
      state.products = [];
    },
    getProductsSuccess: (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = false;
    },
    getProductsFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.products = [];
    },

    addProductRequest: (state) => {
      state.loading = true;
      state.isProductAdded = [];
      state.error = false;
    },
    addProductSuccess: (state, action) => {
      state.loading = false;
      state.isProductAdded = action.payload;
      state.error = false;
    },
    addProductFailure: (state, action) => {
      state.loading = false;
      state.isProductAdded = [];
      state.error = action.payload;
    },

    updateProductRequest: (state) => {
      state.loading = true;
      state.isProductUpdated = [];
      state.error = false;
    },
    updateProductSuccess: (state, action) => {
      state.loading = false;
      state.isProductUpdated = action.payload;
      state.error = false;
    },
    updateProductFailure: (state, action) => {
      state.loading = false;
      state.isProductUpdated = [];
      state.error = action.payload;
    },

    deleteProductRequest: (state) => {
      state.loading = true;
      state.isProductDeleted = [];
      state.error = false;
    },

    deleteProductSuccess: (state, action) => {
      state.loading = false;
      state.isProductDeleted = action.payload;
      state.error = false;
    },

    deleteProductFailure: (state, action) => {
      state.loading = false;
      state.isProductDeleted = [];
      state.error = action.payload;
    },
  },
});

export const ProductActions = productSlice.actions;
export default productSlice;

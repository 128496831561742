import { combineReducers } from 'redux';
import userSlice from './User/UserReducer';
import womenPageSlice from './WomensPage/WomensPageReducer';
import subscriptionsSlice from './Subscriptions/SubscriptionsReducer';
import productSlice from './Products/ProductReducer';
import packagesSlice from './Packages/PackagesReducer';
import ordersSlice from './Orders/OrdersReducer';
import newPageSlice from './NewPage/NewPageReducer';
import menPageSlice from './MensPage/MensPageReducer';
import homePageSlice from './HomePage/HomePageReducer';
import mainPageSlice from './Main/MainPageReducers';
import authPageSlice from './Auth/AuthPageReducer';

const rootReducer = combineReducers({
  userReducer: userSlice.reducer,
  womenPageReducer: womenPageSlice.reducer,
  subscriptionsReducer: subscriptionsSlice.reducer,
  perfumesReducer: productSlice.reducer,
  packagesReducer: packagesSlice.reducer,
  ordersReducer: ordersSlice.reducer,
  newPageReducer: newPageSlice.reducer,
  mensPageReducer: menPageSlice.reducer,
  homePageReducer: homePageSlice.reducer,
  mainPageReducer: mainPageSlice.reducer,
  authPageReducer: authPageSlice.reducer,
});

export default rootReducer;

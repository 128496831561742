import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { isTokenValid } from '../utils/verifyToken';

const ProtectedLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenValid()) {
      navigate('/auth');
    }
  }, [navigate]);

  return isTokenValid() ? <Outlet /> : null;
};

export default ProtectedLayout;

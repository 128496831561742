import { jwtDecode } from 'jwt-decode';
export function isTokenValid() {
  const token = localStorage.getItem('admin-token');
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Invalid token format:', error);
    return false;
  }
}

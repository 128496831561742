import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  totalSubs: {},
  totalSales: {},
  totalOrders: {},
  totalPending: {},
  monthlySales: [],
};

const mainPageSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    getSubsRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.totalSubs = {};
    },
    getSubsSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalSubs = action.payload;
    },
    getSubsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.totalSubs = {};
    },
    getSalesRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.totalSales = {};
    },
    getSalesSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalSales = action.payload;
    },
    getSalesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.totalSales = {};
    },
    getOrdersRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.totalOrders = {};
    },
    getOrdersSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalOrders = action.payload;
    },
    getOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.totalOrders = {};
    },

    getPendingRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.totalPending = {};
    },
    getPendingSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.totalPending = action.payload;
    },
    getPendingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.totalPending = {};
    },

    getMonthlySalesRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.monthlySales = [];
    },
    getMonthlySalesSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.monthlySales = action.payload;
    },
    getMonthlySalesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.monthlySales = [];
    },
  },
});

export const MainPageActions = mainPageSlice.actions;
export default mainPageSlice;

export const customStyles = {
  content: {
    width: '75%',
    height: '75%',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#313131',
    color: 'white',
    borderRadius: '10px',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

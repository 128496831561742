import React from 'react';
import DataTable from 'react-data-table-component';

const generateColumns = (data) => {
  if (!data || data.length === 0) return [];
  console.log('Subscriptions', data);
  return Object.keys(data[0])
    .map((key) => {
      if (key === 'subscriptionId') return null; // Skip `subscriptionId` if you don't want it in the table

      return {
        name: (
          <div className="w-56 text-center">
            <h1>{formatColumnName(key)}</h1>
          </div>
        ),
        selector: (row) => row[key], // Display the value or an empty string if undefined
        sortable: key !== 'action',
        cell: (row) => {
          //console.log('SUBSCRIPTIONS ROWS', row);
          return (
            <div className="w-full text-center">
              {/* Render each cell’s value */}
              {row[key]}
            </div>
          );
        },
      };
    })
    .filter((column) => column != null);
};

const formatColumnName = (name) => {
  return name
    .replace(/([A-Z])/g, ` $1`)
    .replace(/^./, (str) => str.toUpperCase());
};

const SubscriptionsDataTable = ({ dataEntered, editProduct }) => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#323D4E', // Header background color
        color: '#FFFFFF', // Header text color
        fontWeight: 'bold', // Header text weight
        fontSize: '20px',
      },
    },
    rows: {
      style: {
        color: '#FFFFFF', // Default row text color
        backgroundColor: 'black', // Default row background color
        borderBottom: '1px solid white',
      },
    },
    cells: {
      style: {
        padding: '12px', // Cell padding
        fontSize: '20px',
      },
    },
  };

  const transformData = (data) => {
    return data.map((item) => {
      return {
        subscriptionId: item.subscriptionId,
        userName: item.user.name,
        userEmail: item.user.email,
        userPhoneNumber: item.user.phone_number,
        userCountry: item.user.country,
        packageName: item.package.name,
        packagePrice: item.package.price,
        packageDescription: item.package.description,
        products: item.products
          .map((product) => `${product.name} - $${product.price}`)
          .join(', '),
      };
    });
  };
  const columns = generateColumns(transformData(dataEntered), editProduct);
  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={transformData(dataEntered)}
          fixedHeader
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};

export default SubscriptionsDataTable;

import React from 'react';
import './style.css';
const Loader = () => {
  return (
    /* From Uiverse.io by Shoh2008 */
    <div className="w-full h-full flex items-center justify-center">
      <div class="lds-hourglass"></div>
    </div>
  );
};

export default Loader;

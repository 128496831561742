import { MainPageActions } from './MainPageReducers';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getTotalSales = () => async (dispatch) => {
  try {
    dispatch(MainPageActions.getSalesRequest());
    const response = await axios.get(`${API_BASE_URL}/main/totalSales`);
    dispatch(MainPageActions.getSalesSuccess(response.data));
  } catch (e) {
    dispatch(MainPageActions.getSalesFailure(e.message));
  }
};

export const getTotalSubs = () => async (dispatch) => {
  try {
    dispatch(MainPageActions.getSubsRequest());
    const response = await axios.get(`${API_BASE_URL}/main/totalSubscriptions`);
    console.log({ RESPONSE: response });
    dispatch(MainPageActions.getSubsSuccess(response.data));
  } catch (e) {
    dispatch(MainPageActions.getSubsFailure(e.message));
  }
};

export const getTotalOrders = () => async (dispatch) => {
  try {
    dispatch(MainPageActions.getOrdersRequest());
    const response = await axios.get(`${API_BASE_URL}/main/totalOrders`);
    dispatch(MainPageActions.getOrdersSuccess(response.data));
  } catch (e) {
    dispatch(MainPageActions.getOrdersFailure(e.response.data.message));
  }
};

export const getTotalPending = () => async (dispatch) => {
  try {
    dispatch(MainPageActions.getPendingRequest());
    const response = await axios.get(`${API_BASE_URL}/main/pendingOrders`);
    dispatch(MainPageActions.getPendingSuccess(response.data));
  } catch (e) {
    dispatch(MainPageActions.getPendingFailure(e.message));
  }
};

export const getMonthlySales = () => async (dispatch) => {
  try {
    dispatch(MainPageActions.getMonthlySalesRequest());
    const response = await axios.get(`${API_BASE_URL}/main/monthly-sales`);
    dispatch(MainPageActions.getMonthlySalesSuccess(response.data));
  } catch (e) {
    dispatch(MainPageActions.getMonthlySalesFailure(e.message));
  }
};

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../../Shared/customStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  aAIHomeSection,
  dIHomeSection,
} from '../../../Redux/HomePage/HomePageActions';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Loader/Loader';
import { Img } from 'react-image';

const WelcomePageModal = ({ isOpenModal, closeModal, data }) => {
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { error, loading } = useSelector((store) => store.homePageReducer);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onChangeImage = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      toast.error('No Image Selected');
      return;
    }
    if (!loading) {
      dispatch(aAIHomeSection(selectedImage));
    }
  };

  const onDeleteImage = (event) => {
    event.preventDefault();
    if (!loading) {
      dispatch(dIHomeSection(data.id));
    }
  };

  const _closeModal = (type) => {
    setImagePreview(null);
    setSelectedImage(null);
    closeModal(type);
  };

  useEffect(() => {
    if (!loading && !error) {
      _closeModal('addAnotherImage');
      _closeModal('deleteImage');
    }
  }, [error, loading]);

  return (
    <>
      <Modal
        style={customStyles}
        isOpen={isOpenModal.addAnotherImage}
        onRequestClose={() => closeModal('addAnotherImage')}
      >
        {!loading && (
          <div className="flex flex-col gap-7 items-center text-white p-5">
            <h1 className="text-4xl text-center mb-4">Upload Image</h1>

            <form onSubmit={onChangeImage} className="flex flex-col gap-4">
              <input
                type="file"
                onChange={handleImageUpload}
                className="border border-gray-400 rounded-lg p-2 bg-gray-800 text-white"
              />
              <button
                type="submit"
                className="p-2 px-5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Submit
              </button>
            </form>

            {imagePreview && (
              <div className="mt-4 text-center">
                <h2 className="text-2xl mb-2">Image Preview:</h2>
                <img
                  src={imagePreview}
                  alt="Preview"
                  width={150}
                  className="rounded shadow-lg"
                />
              </div>
            )}
          </div>
        )}

        {loading && (
          <div className=" w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isOpenModal.deleteImage}
        onRequestClose={() => closeModal('deleteImage')}
        style={customStyles}
      >
        {!loading && (
          <div className="flex flex-col items-center gap-7 text-white p-5">
            <h1 className="text-4xl text-center mb-4">Delete the Image</h1>
            <Img
              src={process.env.REACT_APP_IMAGE_BASE_URL + data.content}
              alt="background"
              loader={<Loader />}
              width={150}
            ></Img>
            <button
              className="bg-red-500 text-white px-5 p-2 border rounded-lg w-1/2 hover:bg-red-600 transition-colors"
              onClick={(e) => {
                onDeleteImage(e);
              }}
            >
              Yes, Delete
            </button>
          </div>
        )}

        {loading && (
          <div className=" w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        )}
      </Modal>
    </>
  );
};

export default WelcomePageModal;
